import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import {isIOS, isAndroid, isMobile} from 'react-device-detect';

import './App.css';

function App() {
  const url = new URL(window.location.href)
  const [isLoading, setLoading] = useState(true);
  const [code] = useState(url.searchParams.get('code'));
  const [invite, setInvite] = useState({});

  useEffect(() => {
    setLoading(true);
    if (code && code !== null) {
      fetch(`https://ovision-service.com/api/v1/invitations/code/${code}`).then((response) => {
        return response.json();
      })
      .then((response) => {
        console.log(response);
        setInvite(response.data || {});
      }).finally(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [code]);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  let storeText = 'в AppStore или Google Play и введите код из смс.'

  if (isLoading) {
    return null;
  }

  if (isIOS) {
    storeText = <span> в AppStore<br />и введите код из смс.</span>;
  }

  if (isAndroid) {
    storeText = <span> в Google Play<br />и введите код из смс.</span>;
  }

  return (
    <div className="App">
      <div className="info-container">
        <img className="logo" alt="" src="logo.png" />
        {Object.keys(invite).length > 0 ? <div className="info-text-container">
          <h3 className="info-text">Вы получили приглашение<br />в компанию <span className="company-name">{invite.company}</span>.</h3>
          <h3 className="info-text store-text">Чтобы принять его, скачайте приложение {isTabletOrMobile ? null : <br />}{storeText}</h3>
        </div> : <div className="info-text-container">
          <h3 className="info-text">Приглашение не найдено.</h3>
        </div>}
        <div className="store-container">
          {isIOS || !isMobile ? <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/ru/app/o-vision/id1492056715"><img src="iconAppStore.png" alt="" className={`store-icon${!isMobile ? ' margin24' : ''}`} /></a> : null}
          {isAndroid || !isMobile ? <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.ovisionapp"><img src="iconGooglePlay.png" alt="" className="store-icon" /></a> : null}
        </div>
      </div>
      {isTabletOrMobile ? null : <div className="device-container">
        <img className="device" alt="" src="device.png" />
      </div>}
    </div>
  );
}

export default App;
